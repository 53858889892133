import { FunctionPlugin } from "hyperformula";
import parseValue from "../utils/parse-value";
import { JSONPath } from 'jsonpath-plus';

const parseJSONObject = source => {
    let object = source.replace(/^"|"$/g, '')
    return JSON.parse(object)
}

export class ExtractText extends FunctionPlugin {
    extractText(ast, state) {
        return this.runFunction(ast.args, state, this.metadata('EXTRACTTEXT'), (...args) => {
            const [target, key, fieldType] = args
            let property = key
            let object = target

            if (!object) return parseValue('', fieldType)

            try {
                object = parseJSONObject(object)
            } catch {
                try {
                    const regs = [{
                        pattern: '\\n',
                        replacement: '',
                    }, {
                        pattern: '\\r',
                        replacement: '',
                    }, {
                        pattern: '\/\\\\\\"',
                        replacement: `'`,
                    }, {
                        pattern: '\\\\\\"\\"',
                        replacement: `'"`,
                    }, {
                        pattern: '\\\\"',
                        replacement: '"',
                    }, {
                        pattern: '\\"',
                        replacement: '"',
                    }, {
                        pattern: '"{',
                        replacement: '{',
                    }, {
                        pattern: '}"',
                        replacement: '}',
                    }, {
                        pattern: '"[',
                        replacement: '[',
                    }, {
                        pattern: ']"',
                        replacement: ']',
                    }, {
                        pattern: /style=([\\"-:;a-zA-Z0-9#]+)/g,
                        replacement: '',
                    }]
                    regs.forEach(({ pattern, replacement }) => {
                        object = object.replaceAll(pattern, replacement)
                    })
                    object = parseJSONObject(object)
                } catch (e) {
                    object = target
                }
            }
            // When user passes square brackets, undo the curly brackets replacements.
            // Wrap each segments with single quotes so that it gets read correctly by jsonpath.
            const path = `$.${property.replaceAll(/\{([\w\.]+)\}/g, `['$1']`)}`
            let extractedResult = JSONPath({ path, json: object, wrap: false})
            try {
                extractedResult = object.map(o => parseJSONObject(o)?.[property]).join(',')
            } catch {}
            const result = ![undefined, null].includes(extractedResult)
                ? extractedResult
                : ''
            const fallback = typeof object === 'object'
                ? ''
                : object
            const returnValue = ![undefined, null].includes(result)
                ? result
                : fallback
            return parseValue(returnValue, fieldType);
          })
    }
}

ExtractText.implementedFunctions = {
    'EXTRACTTEXT': {
        method: 'extractText',
        parameters: [
            {argumentType: "STRING"}
        ],
        repeatLastArgs: 1,
    }
}